<template>
  <!-- Documents -->
  <div>
    <div class="add-office-header-wrapper">Add Documents</div>
    <div class="add-documents-wrapper">
      <div class="upload-section-wrapper">
        <div class="attach-file-text">Attach Files</div>
        <div class="attach-file-radio-select">
          <a-radio-group v-model:value="value">
            <a-radio :style="radioStyle" :value="1">Static</a-radio>
            <a-radio :style="radioStyle" :value="2">Dynamic</a-radio>
          </a-radio-group>
        </div>
        <div class="add-documents-upload-section">
          <a-upload-dragger
            v-model:fileList="fileList"
            name="file"
            :multiple="true"
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            @change="handleChange"
          >
            <p class="ant-upload-drag-icon">
              <CloudUploadOutlined />
            </p>
            <p class="ant-upload-text">
              Drag and Drop or click to this area to upload file
            </p>
          </a-upload-dragger>
        </div>
      </div>
    </div>
  </div>
</template>