<template>
  <div class="add-entity-wrapper">
    <div>
      <div class="add-office-name-with-number">
        <div class="add-officees-text">
          Entity<span style="color: red">*</span>
        </div>
      </div>
      <a-form>
        <div class="vacancy-search-wrapper">
          <a-form-item v-bind="validateInfos['data.name']">
            <a-input
              v-model:value="EntityModelRef.data.name"
              placeholder="Enter"
              class="entity-input-field"
            />
          </a-form-item>
        </div>
        <div class="add-entity-cancel-save-button">
          <div class="entity-cancel-button" @click="onClickCancelEntity">
            Cancel
          </div>
          <div class="entity-save-button">
            <a-button @click="onSubmitEntity()">Save</a-button>
          </div>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import Services from "@/services/apis";
import { ref, onMounted, computed, reactive, toRaw } from "vue";
import { Form, message, Modal } from "ant-design-vue";
import { useStore } from "vuex";
const useForm = Form.useForm;
export default {
  setup(props, context) {
    const store = useStore();

    const EntityModelRef = reactive({
      clientId: computed(
        () => store.getters["clientStore/getSelectedclientIds"]
      ).value,
      data: {
        name: undefined,
      },
    });
    const rulesRef = reactive({
      'data.name': [{
        required: true,
        message: "Please enter an entity",
      },],
    });
    const { resetFields, validate, validateInfos } = useForm(
      EntityModelRef,
      rulesRef
    );

    const onSubmitEntity = () => {
      validate()
        .then((result) => {
          console.log(toRaw(EntityModelRef.data));

          Services.addEntity(EntityModelRef).then((response) => {
            const { data } = response;
            message.success("Entity Added Successfully");
          });
          context.emit("onClickCancelEntity");
        })
        .catch((err) => {});
    };
    const onClickCancelEntity = () => {
      context.emit("onClickCancelEntity", false);
    };

    return {
      validateInfos,
      resetFields,
      EntityModelRef,
      onSubmitEntity,
      onClickCancelEntity,
    };
  },
};
</script>
