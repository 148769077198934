<template>
  <div
    :class="
      actionSelected
        ? 'accounts-listing-right-action-bar-container'
        : 'accounts-plannerSelected'
    "
  >
    <div class="search-container" v-if="actionSelected">
      <a-input-search />
    </div>

    <div class="toggle-container">
      <div
        style="
          height: 44px;
          border: 1px solid rgba(255, 255, 255, 0.5)
          box-sizing: border-box;
          border-radius: 15px;
        "
      >
        <span>
          <a-button
            type="link"
            @click="onClickAction"
            :class="actionSelected ? 'selected' : 'unSelected'"
          >
            <span v-if="actionSelected" class="selected-text">Action</span>
            <span v-else></span>
          </a-button>
        </span>
        <span>
          <a-button
            type="link"
            @click="onClickPlanner"
            :class="plannerselected ? 'selected' : 'unSelected'"
          >
            <span v-if="plannerselected" class="selected-text">Planner</span>
            <span v-else></span>
          </a-button>
        </span>
      </div>
    </div>
    <div v-if="actionSelected">
      <div class="menu-container">
        <div class="reports-workflow-text">Workflow</div>
        <a-menu
          mode="inline"
          :style="{ height: '100%', borderRight: 0 }"
          :openKeys="selectedMenuKey"
        >
          <a-sub-menu key="vacancy">
            <template #title>
              <span> Vacancy </span>
            </template>
            <a-menu-item key="create_vacancy" @click="createVacancy"
              >Create Vacancy</a-menu-item
            >
          </a-sub-menu>
          <a-sub-menu key="candidate">
            <template #title>
              <span> Candidate </span>
            </template>
            <a-menu-item key="create_candidate" @click="createCandidate"
              >Create Candidate</a-menu-item
            >
          </a-sub-menu>
          <a-sub-menu key="clients">
            <template #title>
              <span> Clients </span>
            </template>
            <a-menu-item key="create_company" @click="createAccount"
              >Create Client</a-menu-item
            >
            <a-menu-item key="create_client_profile" @click="createClientPerson"
              >Create Client Person</a-menu-item
            >
            <a-menu-item key="ragVisible" @click="ragVisible"
              >Edit RAG Status</a-menu-item
            >
            <a-modal
              v-model:visible="ragvisiblity"
              :closable="false"
              destroyOnClose
              :footer="null"
            >
              <a-form :ref="formRef" scrollToFirstError>
                <div>
                  <div class="add-office-header-wrapper">Edit Rag Status</div>
                  <div class="add-subteam-wrapper">
                    <div
                      class="add-office-name-with-number"
                      style="margin-top: 20px"
                    >
                      <div class="add-officees-text">
                        Sub Team<span style="color: red">*</span>
                      </div>
                    </div>
                    <div class="vacancy-search-wrapper">
                      <a-form-item v-bind="validateInfos.subTeam">
                        <a-select
                          ref="select"
                          :options="sub_teamsOptions"
                          v-model:value="TeamModelRef.subTeam"
                          style="width: 100%"
                          @focus="focus"
                          @change="handleChange"
                          placeholder="Select Sub Team"
                          class="entity-select-input-field"
                        >
                        </a-select>
                      </a-form-item>
                    </div>
                    <div
                      class="add-office-name-with-number"
                      style="margin-top: 20px"
                    >
                      <div class="add-officees-text">
                        RAG Status<span style="color: red">*</span>
                      </div>
                    </div>
                    <div class="vacancy-search-wrapper">
                      <a-form-item v-bind="validateInfos.ragStatus">
                        <a-select
                          ref="select"
                          v-model:value="TeamModelRef.ragStatus"
                          :options="rmStatuslist"
                          style="width: 100%"
                          @focus="focus"
                          @change="handleChange"
                          placeholder="Select RAG Status"
                          class="entity-select-input-field"
                        >
                        </a-select>
                      </a-form-item>
                    </div>
                    <div class="add-entity-cancel-save-button">
                      <div @click="closeModal()" class="entity-cancel-button">
                        Cancel
                      </div>
                      <div class="entity-save-button">
                        <a-button @click="onSubmit()">Save</a-button>
                      </div>
                    </div>
                  </div>
                </div>
              </a-form>
            </a-modal>
          </a-sub-menu>
          <a-sub-menu key="sub4">
            <template #title>
              <span> Tasks </span>
            </template>
          </a-sub-menu>

          <a-divider class="reports-side-bar-divider" />
          <div class="action-section">
            <div>
              <p>Data</p>
            </div>
            <a-menu-item class="action-sub-section-text" key="delete_account">
              <DeleteOutlined class="vacancy-right-bar-delete-icon" />Delete
            </a-menu-item>
            <div class="action-sub-section">
              <a-menu-item class="action-sub-section-text" key="merge_account"
                >Merge</a-menu-item
              >
              <a-menu-item class="action-sub-section-text" key="export"
                >Export</a-menu-item
              >
              <a-menu-item
                key="edit_account_profile"
                class="action-sub-section-text"
                @click="editAccountProfile"
              >
                <a href="/company/edit-company" style="color: white">
                  Edit Client Profile</a
                >
              </a-menu-item>
              <div style="padding-left: 20px">
                <a-menu-item key="add_entity">
                  <div
                    class="action-sub-section-text"
                    @click="addEntityVisiblity"
                    :pform-name="entity"
                  >
                    Add Entity
                  </div>
                </a-menu-item>
                <a-menu-item key="add_office">
                  <div
                    class="action-sub-section-text"
                    @click="addOfficeVisiblity"
                    :pform-name="Office"
                  >
                    Add Office
                  </div>
                </a-menu-item>
                <a-menu-item key="add_sub_team">
                  <div
                    class="action-sub-section-text"
                    @click="addSubTeamVisiblity"
                  >
                    Add Sub Teams
                  </div>
                </a-menu-item>
                <a-menu-item
                  key="add_billing_entities"
                  class="action-sub-section-text"
                  >Add Billing Entities</a-menu-item
                >
                <a-menu-item
                  class="action-sub-section-text"
                  @click="createClientPerson"
                  key="add_client_person"
                >
                  Add Client Person
                </a-menu-item>
                <a-menu-item key="add_documents">
                  <div
                    class="action-sub-section-text"
                    @click="addDocsVisiblity"
                  >
                    Add Documents
                  </div>
                </a-menu-item>

                <a-menu-item key="add_terms">
                  <div
                    class="action-sub-section-text"
                    @click="addTermsVisiblity"
                  >
                    Add Terms
                  </div>
                </a-menu-item>
                <a-menu-item key="add_meetings">
                  <div
                    class="action-sub-section-text"
                    @click="openMeetingModal"
                  >
                    Add Meeting
                  </div>
                </a-menu-item>
              </div>
            </div>
          </div>
        </a-menu>
      </div>
    </div>
    <div v-if="plannerselected" class="right-side-bar-planner-wrapper">
      <div class="date-display-wrapper">
        <div class="today-text">Today</div>
        <div class="date-respone-text">17th Dec, 2020</div>
      </div>
      <div>
        <ul style="padding: 17px; margin: 0px">
          <li>Meeting with Client Person at 10 am</li>
          <li>Meeting with Client Person at 11 am</li>
          <li>Task to be completed</li>
        </ul>
      </div>

      <div class="calendar-wrapper">
        <a-calendar
          v-model:value="value"
          :fullscreen="false"
          @panelChange="onPanelChange"
        >
          <template #headerRender="{ value, onChange }">
            <div style="padding: 10px">
              <div style="margin-bottom: 10px">Custom header</div>
              <a-row type="flex" justify="space-between">
                <a-col>
                  <div>
                    <a-popover
                      v-model:visible="visible"
                      trigger="click"
                      placement="bottom"
                    >
                      <template #content>
                        <div
                          style="
                            padding: 5px 0px;
                            font-family: Poppins Medium;
                            cursor: pointer;
                          "
                        >
                          Meeting
                        </div>
                        <div
                          style="
                            padding: 5px 0px;
                            font-family: Poppins Medium;
                            cursor: pointer;
                          "
                        >
                          Task
                        </div>
                        <div
                          style="
                            padding: 5px 0px;
                            font-family: Poppins Medium;
                            cursor: pointer;
                          "
                        >
                          Reminder
                        </div>
                      </template>
                      <div class="open-popover-plus-icon"><PlusOutlined /></div>
                    </a-popover>
                  </div>
                </a-col>
                <a-col>
                  <a-select
                    size="small"
                    :dropdown-match-select-width="false"
                    class="my-year-select"
                    :value="String(value.year())"
                    @change="
                      (newYear) => {
                        onChange(value.clone().year(newYear));
                      }
                    "
                  >
                    <a-select-option
                      v-for="val in getYears(value)"
                      :key="String(val)"
                      class="year-item"
                    >
                      {{ val }}
                    </a-select-option>
                  </a-select>
                </a-col>
                <a-col>
                  <a-select
                    size="small"
                    :dropdown-match-select-width="false"
                    :value="String(value.month())"
                    @change="
                      (selectedMonth) => {
                        onChange(
                          value.clone().month(parseInt(selectedMonth, 10))
                        );
                      }
                    "
                  >
                    <a-select-option
                      v-for="(val, index) in getMonths(value)"
                      :key="String(index)"
                      class="month-item"
                    >
                      {{ val }}
                    </a-select-option>
                  </a-select>
                </a-col>
              </a-row>
            </div>
          </template>
        </a-calendar>
      </div>

      <div>
        <div
          style="
            display: flex;
            border-bottom: 1px solid #b3b1b4;
            cursor: pointer;
          "
          @click="OnclickArrow"
        >
          <div class="calendar-text">Calendar</div>
          <div style="margin-left: auto" v-if="hideCalendarOptions">
            <DownOutlined />
          </div>
          <div style="margin-left: auto" v-else>
            <UpOutlined />
          </div>
        </div>
      </div>
      <div style="display: grid; margin-top: 10px" v-if="hideCalendarOptions">
        <a-checkbox v-model:checked="checked" style="margin-left: 0px"
          >Meetings</a-checkbox
        >
        <a-checkbox v-model:checked="checked" style="margin-left: 0px"
          >Tasks</a-checkbox
        >
        <a-checkbox v-model:checked="checked" style="margin-left: 0px"
          >Reminders</a-checkbox
        >
      </div>
    </div>

    <a-divider class="meeting-calendar-divider-section" v-if="actionSelected" />

    <div class="icons-wrapper" v-if="actionSelected">
      <CalendarOutlined
        style="margin-right: 10px; cursor: pointer"
        @click="fethMeeting()"
      /><MailOutlined style="margin-right: 20px; cursor: pointer" />
    </div>

    <a-modal
      v-model:visible="meetingModal"
      @ok="hideModal"
      :footer="null"
      width="1000px"
      destroyOnClose
    >
      <MeetingModal @closeMeeting="MeetingModalOk" />
      <template #footer>
        <a-button
          type="link"
          style="
            color: rgb(76, 81, 85);
            font-size: $font_size_12;
            font-family: Poppins;
            font-weight: 500;
          "
          key="back"
          @click="accountMeetingModalCancel"
          >Cancel</a-button
        >
        <a-button
          key="submit"
          style="
            background: RGB(228, 129, 106);
            color: #ffffff;
            border-radius: 15px;
            font-size: $font_size_12;
            font-family: Poppins;
            font-weight: 500;
          "
          @click="accountMeetingModalSave"
          >Save</a-button
        >
      </template>
    </a-modal>
    <div>
      <a-modal
        class="entity-modal"
        title=""
        width="500px"
        v-model:visible="addEntityVisible"
        :closable="false"
        centered
        footer=""
        destroyOnClose
        style="padding: 10px"
      >
        <div class="parent-pop-over-account">
          <div class="add-office-header-wrapper">Add Entity</div>
          <AddEntity @onClickCancelEntity="onClickCancelEntity" />
        </div>
      </a-modal>
    </div>
    <div>
      <a-modal
        class="office-modal"
        title=""
        width="500px"
        v-model:visible="addOfficeVisible"
        :closable="false"
        centered
        footer=""
        destroyOnClose
        style="padding: 10px"
      >
        <AddWork @onClickCancelOffice="onClickCancelOffice" />
      </a-modal>
      <a-modal
        v-model:visible="addSubTeamVisible"
        :closable="false"
        destroyOnClose
        :footer="null"
      >
        <AddSubTeam @onClickCancelSubTeam="onClickCancelSubTeam" />
      </a-modal>

      <a-modal
        class="docs-modal"
        title=""
        width="500px"
        v-model:visible="addDocsVisible"
        :closable="false"
        centered
        footer=""
        destroyOnClose
        style="padding: 10px"
      >
        <AddDocs @onClickCancelDocs="onClickCancelDocs" />
      </a-modal>
      <a-modal
        class="terms-modal"
        title=""
        width="500px"
        v-model:visible="addTermsVisible"
        :closable="false"
        centered
        footer=""
        destroyOnClose
        style="padding: 10px"
      >
        <AddTerms @onClickCancelTerms="onClickCancelTerms" />
      </a-modal>
    </div>
  </div>
</template>
<script>
import {
  DeleteOutlined,
  DownOutlined,
  UpOutlined,
  PlusOutlined,
  CalendarOutlined,
  MailOutlined,
} from "@ant-design/icons-vue";
import Services from "@/services/apis";
import { ref, onMounted, computed, reactive } from "vue";
import { useRouter } from "vue-router";
import { Form, message, Modal } from "ant-design-vue";
import PopOverForm from "../components/popOverForm.vue";
import MeetingModal from "../components/MeetingModal.vue";
import { useStore } from "vuex";
import regionVue from "../../master/components/masterFeature/location/region.vue";
import AddWork from "./AddOffice.vue";
import AddDocs from "./AddDocs.vue";
import AddEntity from "./AddEntityModal.vue";
import AddTerms from "./AddTerms.vue";
import AddSubTeam from "./AddSubTeam.vue";
let actionSelected = ref(true);
let plannerselected = ref(false);
let hideCalendarOptions = ref(true);
let payload;
const useForm = Form.useForm;
export default {
  components: {
    DeleteOutlined,
    DownOutlined,
    UpOutlined,
    PlusOutlined,
    AddSubTeam,
    CalendarOutlined,
    MailOutlined,
    MeetingModal,
    AddWork,
    AddDocs,
    AddTerms,
    AddEntity,
  },
  setup(props, context) {
    const store = useStore();
    const selectedMenuKey = ref(["clients"]);
    const router = useRouter();
    const value = ref();
    const visible = ref(false);
    let meetingModal = ref(false);
    let addEntityVisible = ref(false);
    let addOfficeVisible = ref(false);
    let addSubTeamVisible = ref(false);
    let addDocsVisible = ref(false);
    let addTermsVisible = ref(false);
    const ragvisiblity = ref(false);
    const TeamModelRef = reactive({
      clientId: ref(),
      data: {
        subTeamId: ref(),
        ragStatusId: ref(),
      },
    });

    const addOfficeVisiblity = () => {
      let selectSubTeamLength = computed(
        () => store.getters["clientStore/getSelectedclientIds"]
      ).value.length;
      if (selectSubTeamLength) {
        addOfficeVisible.value = true;
      } else {
        ragvisiblity.value = false;
        message.error("Please Seelect atleast one Client from the Listing");
      }
      
    };
    const addSubTeamVisiblity = () => {
      let selectSubTeamLength = computed(
        () => store.getters["clientStore/getSelectedclientIds"]
      ).value.length;
      TeamModelRef.clientId = computed(
        () => store.getters["clientStore/getSelectedclientIds"]
      ).value;
      if (selectSubTeamLength) {
        addSubTeamVisible.value = true;
      } else {
        ragvisiblity.value = false;
        message.error("Please Seelect atleast one Client from the Listing");
      }
    };
    const addEntityVisiblity = () => {
      let selectSubTeamLength = computed(
        () => store.getters["clientStore/getSelectedclientIds"]
      ).value.length;
      if (selectSubTeamLength) {
        addEntityVisible.value = true;
      } else {
        ragvisiblity.value = false;
        message.error("Please Seelect atleast one Client from the Listing");
      }
    };
    const addDocsVisiblity = () => {
      let selectDocsLength = computed(
        () => store.getters["clientStore/getSelectedclientIds"]
      ).value.length;
      if (selectDocsLength) {
        addDocsVisible.value = true;
      } else {
        ragvisiblity.value = false;
        message.error("Please Seelect atleast one Client from the Listing");
      }
      
    };
    const addTermsVisiblity = () => {
      let selectTermsLength = computed(
        () => store.getters["clientStore/getSelectedclientIds"]
      ).value.length;
      if (selectTermsLength) {
        addTermsVisible.value = true;
      } else {
        ragvisiblity.value = false;
        message.error("Please Seelect atleast one Client from the Listing");
      }
      
    };

    const onClickCancelEntity = (value) => {
      addEntityVisible.value = value;
    };
    const onClickCancelOffice = (value) => {
      addOfficeVisible.value = value;
    };
    const onClickCancelSubTeam = (value) => {
      addSubTeamVisible.value = value;
    };
    const onClickCancelDocs = (value) => {
      addDocsVisible.value = value;
    };
    const onClickCancelTerms = (value) => {
      addTermsVisible.value = value;
    };

    const formRef = ref();

    const rulesRef = reactive({
      subTeam: [
        {
          required: true,
          message: "Please select a SubTeam",
        },
      ],
      ragStatus: [
        {
          required: true,
          message: "Please select a Rag Status",
        },
      ],
    });

    const { resetFields, validate, validateInfos } = useForm(
      TeamModelRef,
      rulesRef
    );
    const MeetingModalOk = () => {
      meetingModal.value = false;
    };

    const createVacancy = () => {
      router.push(`/vacancy/create-new-vacancy`);
    };
    const createCandidate = () => {
      router.push(`/add-candidate`);
    };

    const fethMeeting = () => {
      router.push("/meetings");
    };
    const hide = () => {
      visible.value = false;
    };
    const createAccount = () => {
      router.push(`/add-account`);
    };
    const createClientPerson = () => {
      router.push(`/add-client-person`);
    };
    const closeModal = () => {
      ragvisiblity.value = false;
    };
    const ragVisible = () => {
      let selectRagLength = computed(
        () => store.getters["clientStore/getSelectedclientIds"]
      ).value.length;
      if (selectRagLength) {
        ragvisiblity.value = true;
      } else {
        ragvisiblity.value = false;
        message.error("Please Seelect atleast one Client from the Listing");
      }
    };

    const editAccountProfile = () => {
      router.push(`/company/edit-company`);
    };
    const onClickAction = () => {
      plannerselected.value = false;
      actionSelected.value = true;
    };
    const onClickPlanner = () => {
      plannerselected.value = true;
      actionSelected.value = false;
    };
    const OnclickArrow = () => {
      hideCalendarOptions.value = !hideCalendarOptions.value;
    };

    const getYears = (value) => {
      const year = value.year();
      const years = [];

      for (let i = year - 10; i < year + 10; i += 1) {
        years.push(i);
      }

      return years;
    };

    const onPanelChange = (value, mode) => {
      console.log(value, mode);
    };

    const getMonths = (value) => {
      const current = value.clone();
      const localeData = value.localeData();
      const months = [];

      for (let i = 0; i < 12; i++) {
        current.month(i);
        months.push(localeData.monthsShort(current));
      }

      return months;
    };
    const entityVisible = ref(false);
    const officeVisible = ref(false);
    const teamVisible = ref(false);
    const entity = "entity";
    const Office = "Office";
    const subTeam = "subTeam";
    const documents = "documents";
    const addTerms = "addTerms";

    const openMeetingModal = () => {
      meetingModal.value = true;
    };
    const accountMeetingModalCancel = () => {
      meetingModal.value = false;
    };
    const accountMeetingModalSave = () => {
      meetingModal.value = false;
    };
    const rmStatuslist = ref([]);
    const sub_teamsOptions = ref([]);
    let listData;
    const getSubteam = (id) => {
      Services.teamListing({ types: ["SUB_TEAM"] })
        .then((response) => {
          const { data } = response;

          let tempTeams = [];
          data.data.map((x) => {
            tempTeams.push({
              value: x.id,
              label: x.name,
            });
          });
          Object.assign(sub_teamsOptions.value, tempTeams);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const getRmStatusDetail = () => {
      Services.getRmStatusDetail((payload = { types: "RM" }))
        .then((response) => {
          const { data } = response;
          let listData = [];
          data.data.map((x) => {
            listData.push({
              value: x.id,
              label: x.name,
            });
          });
          Object.assign(rmStatuslist.value, listData);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const onSubmit = () => {
      validate();
      // .then((result) => {
      //   Services.getMeetings(modelRef).then((response) => {
      //     const { data } = response;

      //     message.success("Rag Status Updated Successfully");
      //     // location.reload();
      //   });
      //   context.emit("closeMeeting");
      // })
      // .catch((err) => {});
    };
    
    
    onMounted(() => {
      getRmStatusDetail();
      getSubteam();
    });

    return {
      validate,
      validateInfos,
      resetFields,
      formRef,
      createAccount,
      selectedMenuKey,
      onSubmit,
      TeamModelRef,
      sub_teamsOptions,
      MeetingModalOk,
      closeModal,
      ragVisible,
      ragvisiblity,
      addEntityVisiblity,
      addOfficeVisiblity,
      addSubTeamVisiblity,
      addTermsVisiblity,
      addDocsVisiblity,
      addEntityVisible,
      addOfficeVisible,
      addSubTeamVisible,
      addDocsVisible,
      addTermsVisible,
      onClickCancelEntity,
      onClickCancelOffice,
      onClickCancelSubTeam,
      onClickCancelDocs,
      onClickCancelTerms,
      rmStatuslist,
      createClientPerson,
      createVacancy,
      createCandidate,
      editAccountProfile,
      onClickAction,
      actionSelected,
      plannerselected,
      fethMeeting,
      onClickPlanner,
      value,
      onPanelChange,
      OnclickArrow,
      hideCalendarOptions,
      checked: ref(false),
      getYears,
      getMonths,
      visible,
      hide,
      officeVisible,
      entityVisible,
      teamVisible,
      subTeam,
      Office,
      entity,
      collapsed: ref(false),
      documents,
      addTerms,
      meetingModal,
      openMeetingModal,
      accountMeetingModalCancel,
      accountMeetingModalSave,
      
    };
  },
};
</script>
<style lang="scss">
@import "../styles/rightNav.scss";
</style>
