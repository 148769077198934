<template>
  <a-form>
    <div>
      <div class="parent-pop-over-account">
        <div class="add-office-header-wrapper">Add Office</div>
        <div class="add-officees-wrapper">
          <div>
            <div class="add-office-name-with-number">
              <div class="add-officees-text">
                Name<span style="color: red">*</span>
              </div>
            </div>
            <div class="vacancy-search-wrapper">
              <a-form-item v-bind="validateInfos['data.name']">
              <a-input
                v-model:value="officeModelRef.data.name"
                placeholder="Enter Office Name"
                class="entity-input-field"
              />
              </a-form-item>
            </div>
            <div class="add-office-name-with-number" style="margin-top: 20px">
              <div class="add-officees-text">
                Type<span style="color: red">*</span>
              </div>
            </div>
            <div class="vacancy-search-wrapper">
              <a-form-item v-bind="validateInfos['data.type']">
              <a-select
                ref="select"
                v-model:value="officeModelRef.data.type"
                :options="unitList"
                style="width: 100%"
                @focus="focus"
                @change="handleChange"
                placeholder="Select Type"
                class="entity-select-input-field"
              >
              </a-select>
              </a-form-item>
            </div>
            <div class="add-office-name-with-number" style="margin-top: 20px">
              <div class="add-officees-text">
                Address<span style="color: red">*</span>
              </div>
            </div>
            <div class="vacancy-search-wrapper">
              <a-form-item v-bind="validateInfos['data.address.street']">
              <a-input
                v-model:value="officeModelRef.data.address.street"
                placeholder="Enter Office Address"
                class="entity-input-field"
                style="height: 90px"
              />
              </a-form-item>
            </div>
          </div>
          <div class="add-entity-cancel-save-button">
            <div class="entity-cancel-button" @click="onClickCancelOffice">
              Cancel
            </div>
            <div class="entity-save-button">
              <a-button @click="onSubmitOffice()">Save</a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-form>
</template>
<script>
import Services from "@/services/apis";
import { ref, onMounted, computed, reactive, toRaw } from "vue";
import { Form, message, Modal } from "ant-design-vue";
import { useStore } from "vuex";
const useForm = Form.useForm;

export default {
  setup(props, context) {
    const onClickCancelOffice = () => {
      context.emit("onClickCancelOffice", false);
    };
    let payload = [];
    const unitList = ref([]);
    const store = useStore();
    const getUnitType = () => {
      let payload = {
        q: "",
        pageNo: 0,
        types: ["UNIT"],
      };
      Services.resumeListing(payload)
        .then((response) => {
          const { data } = response;
          let listData = [];
          data[0].data.map((x) => {
            listData.push({
              value: x.id,
              label: x.name,
            });
          });
          Object.assign(unitList.value, listData);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const officeModelRef = reactive({
      clientId: computed(
        () => store.getters["clientStore/getSelectedclientIds"]
      ).value,
      data: {
        name: ref(),
        type: ref(),
        address: ref({
          type: "",
          street: "",
          city: "",
          state: "",
          country: "",
          zip: "",
        }),
      },
    });
    const rulesRef = reactive({
      "data.name": [
        {
          required: true,
          message: "Please add a name",
        },
      ],
      "data.type": [
        {
          required: true,
          message: "Please select a type",
        },
      ],
      "data.address.street": [
        {
          required: true,
          message: "Please add Address",
        },
      ],
    });
    const { resetFields, validate, validateInfos } = useForm(
      officeModelRef,
      rulesRef
    );
    onMounted(() => {
      getUnitType();
    });

    const onSubmitOffice = () => {
      validate()
        .then((result) => {
          Services.addOffice(officeModelRef).then((response) => {
            const { data } = response;
            message.success("Office Added Successfully");
          });
          context.emit("onClickCancelOffice");
        })
        .catch((err) => {});
    };
    return {
      validateInfos,
      resetFields,
      officeModelRef,
      onSubmitOffice,
      onClickCancelOffice,
      getUnitType,
      unitList,
    };
  },
};
</script>
