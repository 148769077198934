<template>
  <!-- Add Teams -->
  <div>
    <a-form>
    <div class="add-office-header-wrapper">Add Terms</div>
    <div class="add-documents-wrapper">
      <div class="add-office-name-with-number" style="margin-top: 20px">
        <div class="add-officees-text">
          Level<span style="color: red">*</span>
        </div>
      </div>
      <div class="vacancy-search-wrapper">
        <a-form-item v-bind="validateInfos['data.name']">
        <a-input
          v-model:value="termsModelRef.data.name"
          placeholder="Enter"
          class="entity-input-field"
        />
        </a-form-item>
      </div>
      <div class="add-office-name-with-number" style="margin-top: 20px">
        <div class="add-officees-text">
          Is Fixed<span style="color: red">*</span>
        </div>
      </div>
      <div class="vacancy-search-wrapper">
        
        <a-radio-group v-model:value="termsModelRef.data.isFixed">
          <a-radio :style="radioStyle" value="yes" >Yes</a-radio>
          <a-radio :style="radioStyle" value="no">No</a-radio>
        </a-radio-group>
        
      </div>
      <div class="add-office-name-with-number" style="margin-top: 20px">
        <div class="add-officees-text">
          Fixed Fee<span style="color: red">*</span>
        </div>
      </div>
      <div class="vacancy-search-wrapper">
        <a-form-item v-bind="validateInfos['data.fixedFee']">
        <a-input
          v-model:value="termsModelRef.data.fixedFee"
          placeholder="Enter"
          class="entity-input-field"
        />
        </a-form-item>
      </div>
      <div class="add-office-name-with-number" style="margin-top: 20px">
        <div class="add-officees-text">
          Percentage<span style="color: red">*</span>
        </div>
      </div>
      <div class="vacancy-search-wrapper">
        <a-form-item v-bind="validateInfos['data.percentage']">
        <a-input
          v-model:value="termsModelRef.data.percentage"
          placeholder="Enter"
          class="entity-input-field"
        />
        </a-form-item>
      </div>
      <div class="add-office-name-with-number" style="margin-top: 20px">
        <div class="add-officees-text">
          Fee Limit<span style="color: red">*</span>
        </div>
      </div>
      <div class="vacancy-search-wrapper">
        <a-form-item v-bind="validateInfos['data.fee']">
        <a-input
          v-model:value="termsModelRef.data.fee"
          placeholder="Enter"
          class="entity-input-field"
        />
        </a-form-item>
      </div>
      <div class="add-entity-cancel-save-button">
        <div class="entity-cancel-button" @click="onClickCancelTerms">
          Cancel
        </div>
        <div class="entity-save-button"><a-button @click="onSubmitTerms()">Save</a-button></div>
      </div>
    </div>
    </a-form>
  </div>
</template>
<script>
import Services from "@/services/apis";
import { ref, onMounted, computed, reactive, toRaw } from "vue";
import { Form, message, Modal } from "ant-design-vue";
import { useStore } from "vuex";
const useForm = Form.useForm;
export default {
  setup(props, context) {
    let payload = [];
    const store = useStore();

    const termsModelRef = reactive({
      clientId: computed(
        () => store.getters["clientStore/getSelectedclientIds"]
      ).value,
      data: {
        name: ref(),
        isFixed: ref('no' ),
        percentage: ref(),
        fee: ref(),
        fixedFee: ref(),
      },
    });
    const rulesRef = reactive({
      // "data.name": [
      //   {
      //     required: true,
      //     message: "Please add this field",
      //   },
      // ],
      // "data.fixedFee": [
      //   {
      //     required: true,
      //     message: "Please select this field",
      //   },
      // ],
      // "data.percentage": [
      //   {
      //     required: true,
      //     message: "Please add this field",
      //   },
      // ],
      // "data.fee": [
      //   {
      //     required: true,
      //     message: "Please add this field",
      //   },
      // ],
    });
    const { resetFields, validate, validateInfos } = useForm(
      termsModelRef,
      rulesRef
    );
    
    const onSubmitTerms = () => {
      validate()
        .then((result) => {
          Services.addTerms(termsModelRef).then((response) => {
            const { data } = response;
            message.success("Terms Added Successfully");
          });
          context.emit("onClickCancelTerms");
        })
        .catch((err) => {});
    };
    const onClickCancelTerms = () => {
      context.emit("onClickCancelTerms", false);
    };

    return {
      validateInfos,
      resetFields,
      termsModelRef,
      onSubmitTerms,
      onClickCancelTerms,
    };
  },
};
</script>
