<template>
  <div>
    
  

    <!-- Documents -->
    <div v-if="props.formName == 'documents'">
      <div class="add-office-header-wrapper">Add Documents</div>
      <div class="add-documents-wrapper">
        <div class="upload-section-wrapper">
          <div class="attach-file-text">Attach Files</div>
          <div class="attach-file-radio-select">
            <a-radio-group v-model:value="value">
              <a-radio :style="radioStyle" :value="1">Static</a-radio>
              <a-radio :style="radioStyle" :value="2">Dynamic</a-radio>
            </a-radio-group>
          </div>
          <div class="add-documents-upload-section">
            <a-upload-dragger
              v-model:fileList="fileList"
              name="file"
              :multiple="true"
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              @change="handleChange"
            >
              <p class="ant-upload-drag-icon">
                <CloudUploadOutlined />
              </p>
              <p class="ant-upload-text">
                Drag and Drop or click to this area to upload file
              </p>
            </a-upload-dragger>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Teams -->
    <div v-if="props.formName == 'addTerms'">
      <div class="add-office-header-wrapper">Add Terms</div>
      <div class="add-documents-wrapper">
        <div class="add-office-name-with-number" style="margin-top: 20px">
          <div class="add-officees-text">
            Level<span style="color: red">*</span>
          </div>
        </div>
        <div class="vacancy-search-wrapper">
          <a-input
            v-model:value="value"
            placeholder="Enter"
            class="entity-input-field"
          />
        </div>
        <div class="add-office-name-with-number" style="margin-top: 20px">
          <div class="add-officees-text">
            Is Fixed<span style="color: red">*</span>
          </div>
        </div>
        <div class="vacancy-search-wrapper">
          <a-radio-group>
            <a-radio :style="radioStyle" :value="1">Yes</a-radio>
            <a-radio :style="radioStyle" :value="2">No</a-radio>
          </a-radio-group>
        </div>
        <div class="add-office-name-with-number" style="margin-top: 20px">
          <div class="add-officees-text">
            Fixed Fee<span style="color: red">*</span>
          </div>
        </div>
        <div class="vacancy-search-wrapper">
          <a-input
            v-model:value="value"
            placeholder="Enter"
            class="entity-input-field"
          />
        </div>
        <div class="add-office-name-with-number" style="margin-top: 20px">
          <div class="add-officees-text">
            Percentage<span style="color: red">*</span>
          </div>
        </div>
        <div class="vacancy-search-wrapper">
          <a-input
            v-model:value="value"
            placeholder="Enter"
            class="entity-input-field"
          />
        </div>
        <div class="add-office-name-with-number" style="margin-top: 20px">
          <div class="add-officees-text">
            Fee Limit<span style="color: red">*</span>
          </div>
        </div>
        <div class="vacancy-search-wrapper">
          <a-input
            v-model:value="value"
            placeholder="Enter"
            class="entity-input-field"
          />
        </div>
        <div class="add-entity-cancel-save-button">
          <div class="entity-cancel-button">Cancel</div>
          <div class="entity-save-button"><a-button>Save</a-button></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, toRefs } from "vue";
import { CloudUploadOutlined } from "@ant-design/icons-vue";

export default {
  components: {
    CloudUploadOutlined,
  },

  props: ["form-name"],
  setup(props) {
    console.log("props :>> ", props);
    const value = ref("");
    return {
      props,
      value,
    };
  },
};
</script>

<style lang="scss">
@import "../styles/popOverForm.scss";
</style>