<template>
  <a-form>
    <div>
      <div class="add-office-header-wrapper">Add Sub Team</div>
      <div class="add-subteam-wrapper">
        <div class="add-office-name-with-number" style="margin-top: 20px">
          <div class="add-officees-text">
            Sub Team<span style="color: red">*</span>
          </div>
        </div>
        <div class="vacancy-search-wrapper">
          <a-form-item v-bind="validateInfos['data.id']">
            <a-select
              ref="select"
              :options="sub_teamsOptions"
              v-model:value="SubTeamModelRef.data.id"
              style="width: 100%"
              @focus="focus"
              @change="handleChange"
              placeholder="Select Sub Team"
              class="entity-select-input-field"
            >
            </a-select>
          </a-form-item>
        </div>
        <div class="add-office-name-with-number" style="margin-top: 20px">
          <div class="add-officees-text">
            RAG Status<span style="color: red">*</span>
          </div>
        </div>
        <div class="vacancy-search-wrapper">
          <a-form-item v-bind="validateInfos['data.status']">
            <a-select
              ref="select"
              v-model:value="SubTeamModelRef.data.status"
              :options="rmStatuslist"
              style="width: 100%"
              @focus="focus"
              @change="handleChange"
              placeholder="Select RAG Status"
              class="entity-select-input-field"
            >
            </a-select>
          </a-form-item>
        </div>
        <div class="add-entity-cancel-save-button">
          <div @click="onClickCancelSubTeam()" class="entity-cancel-button">Cancel</div>
          <div class="entity-save-button">
            <a-button @click="onSubmitSubTeam()">Save</a-button>
          </div>
        </div>
      </div>
    </div>
  </a-form>
</template>
<script>
import Services from "@/services/apis";
import { ref, onMounted, computed, reactive, toRaw } from "vue";
import { Form, message, Modal } from "ant-design-vue";
import { useStore } from "vuex";
const useForm = Form.useForm;
export default {
  setup(props, context) {
    let payload = [];
    const rmStatuslist = ref([]);
    const sub_teamsOptions = ref([]);
    const store = useStore();
    const getSubteam = (id) => {
      Services.teamListing({ types: ["SUB_TEAM"] })
        .then((response) => {
          const { data } = response;

          let tempTeams = [];
          data.data.map((x) => {
            tempTeams.push({
              value: x.id,
              label: x.name,
            });
          });
          Object.assign(sub_teamsOptions.value, tempTeams);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const getRmStatusDetail = () => {
      Services.getRmStatusDetail((payload = { types: "RM" }))
        .then((response) => {
          const { data } = response;
          let listData = [];
          data.data.map((x) => {
            listData.push({
              value: x.id,
              label: x.name,
            });
          });
          Object.assign(rmStatuslist.value, listData);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const SubTeamModelRef = reactive({
      clientId: computed(
        () => store.getters["clientStore/getSelectedclientIds"]
      ).value,
      data: {
        id: ref(),
        status: ref(),
      },
    });
    const rulesRef = reactive({
      'data.id': [
        {
          required: true,
          message: "Please select a SubTeam",
        },
      ],
      'data.status': [
        {
          required: true,
          message: "Please select a Rag Status",
        },
      ],
    });
    const { resetFields, validate, validateInfos } = useForm(
      SubTeamModelRef,
      rulesRef
    );
    onMounted(() => {
      getRmStatusDetail();
      getSubteam();
    });

    const onSubmitSubTeam = () => {
      validate()
        .then((result) => {
          console.log(toRaw(SubTeamModelRef.data));

          Services.addSubTeam(SubTeamModelRef).then((response) => {
            const { data } = response;
            message.success("Sub Team Added Successfully");
          });
          context.emit("onClickCancelSubTeam");
        })
        .catch((err) => {});
    };
    const onClickCancelSubTeam = () => {
      context.emit("onClickCancelSubTeam", false);
    };

    return {
      sub_teamsOptions,
      rmStatuslist,
      validateInfos,
      resetFields,
      SubTeamModelRef,
      onSubmitSubTeam,
      onClickCancelSubTeam,
    };
  },
};
</script>
